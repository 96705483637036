// import logo from './logo.svg';
import React from "react";
import client from "./Service/Client"; // Contentful
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  withRouter,
} from "react-router-dom";
import "./App.css";
/* import '@ionic/react/css/core.css'; */
import { SafeAreaController } from "@aashu-dubey/capacitor-statusbar-safe-area";

/* import ChatManager from './Components/ChatManager' */

import { SplashPage } from "./Pages/SplashPage/SplashPage";
import { EnterPin } from "./Pages/EnterPin/EnterPin";
import Home from "./Pages/Home/Home";

import { AboutVitala } from "./Pages/HamburgerMenuPages/AboutVitala";
import { PrivacyPrinciples } from "./Pages/HamburgerMenuPages/PrivacyPrinciples";
import { PrivacyPolicy } from "./Pages/HamburgerMenuPages/PrivacyPolicy";
import { PinSetUp } from "./Pages/HamburgerMenuPages/PinSetUp";
import { UserSecurity } from "./Pages/HamburgerMenuPages/UserSecurity";
import { Feedback } from "./Pages/HamburgerMenuPages/Feedback";
import Language from "./Pages/HamburgerMenuPages/Language";

/* import { NotificationsInbox } from './Pages/Notifications/NotificationsInbox'
import { ActivateNotifications } from './Pages/Notifications/ActivateNotifications'
import { DeactivateNotifications } from './Pages/Notifications/DeactivateNotifications' */

/* import { Hour3Notification } from './Pages/Notifications/Hour3Notification'
import { Hour6Notification } from './Pages/Notifications/Hour6Notification'
import { Hour36Notification } from './Pages/Notifications/Hour36Notification'
import { Hour72Notification } from './Pages/Notifications/Hour72Notification'
import { Day8Notification } from './Pages/Notifications/Day8Notification'
import { Day15Notification } from './Pages/Notifications/Day15Notification'
import { Day30Notification } from './Pages/Notifications/Day30Notification' */

import { SelfCareAdvice } from "./Pages/SelfCareAdvice/SelfCareAdvice";

import { AbortionStepsMenu } from "./Pages/AbortionSteps/AbortionStepsMenu";
import { AbortionStep1 } from "./Pages/AbortionSteps/AbortionStep1";
import { AbortionStep2 } from "./Pages/AbortionSteps/AbortionStep2";
import { AbortionStep3 } from "./Pages/AbortionSteps/AbortionStep3";
import { AbortionStep4 } from "./Pages/AbortionSteps/AbortionStep4";
import { AbortionPillRegimenA } from "./Pages/AbortionSteps/AbortionPillRegimenA";
import { AbortionPillRegimenB } from "./Pages/AbortionSteps/AbortionPillRegimenB";
import { AbortionStep5 } from "./Pages/AbortionSteps/AbortionStep5";
import { AbortionStep6 } from "./Pages/AbortionSteps/AbortionStep6";
import { AbortionStep7 } from "./Pages/AbortionSteps/AbortionStep7";
import { AbortionStep7Failed } from "./Pages/AbortionSteps/AbortionStep7Failed";
import { AbortionStep7Passed } from "./Pages/AbortionSteps/AbortionStep7Passed";

import { AbortionAssessment1A } from "./Pages/AbortionAssessment/AbortionAssessment1A";
import { AbortionAssessment1B } from "./Pages/AbortionAssessment/AbortionAssessment1B";
import { AbortionAssessment2 } from "./Pages/AbortionAssessment/AbortionAssessment2";
import { AbortionAssessment3 } from "./Pages/AbortionAssessment/AbortionAssessment3";
import { AbortionAssessment4 } from "./Pages/AbortionAssessment/AbortionAssessment4";
// import { AbortionAssessment5 } from './Pages/AbortionAssessment/AbortionAssessment5'
import { AbortionAssessmentPassed } from "./Pages/AbortionAssessment/AbortionAssessmentPassed";
import { AbortionAssessmentFailed } from "./Pages/AbortionAssessment/AbortionAssessmentFailed";

import { ContraceptionAssessmentOptionsMenu } from "./Pages/ContraceptionAssessment/ContraceptionAssessmentOptionsMenu";
import { ContraceptionPills } from "./Pages/Contraception/ContraceptionPills";
import { ContraceptionCopperIud } from "./Pages/Contraception/ContraceptionCopperIud";
import { ContraceptionMirenaIus } from "./Pages/Contraception/ContraceptionMirenaIus";
import { ContraceptionImplant } from "./Pages/Contraception/ContraceptionImplant";
import { ContraceptionInjection } from "./Pages/Contraception/ContraceptionInjection";
import { ContraceptionCondoms } from "./Pages/Contraception/ContraceptionCondoms";
import { ContraceptionTl } from "./Pages/Contraception/ContraceptionTl";
import { ContraceptionEc } from "./Pages/Contraception/ContraceptionEc";
import { ContraceptionVasectomia } from "./Pages/Contraception/ContraceptionVasectomia";
import { ContraceptionFam } from "./Pages/Contraception/ContraceptionFam";
import { ContraceptionWithdrawal } from "./Pages/Contraception/ContraceptionWithdrawal";
import ContraceptionDiaphragm from "./Pages/Contraception/ContraceptionDiaphragm";
import ContraceptionCap from "./Pages/Contraception/ContraceptionCap";
import { ContraceptionPatch } from "./Pages/Contraception/ContraceptionPatch";
import ContraceptionRing from "./Pages/Contraception/ContraceptionRing";

import { ContraceptionMenu } from "./Pages/Contraception/ContraceptionMenu";
import { ContraceptionAssessment } from "./Pages/ContraceptionAssessment/ContraceptionAssessment";
import { ContraceptionAssessment1 } from "./Pages/ContraceptionAssessment/ContraceptionAssessment1";
import { ContraceptionAssessment2 } from "./Pages/ContraceptionAssessment/ContraceptionAssessment2";
import { ContraceptionAssessment3 } from "./Pages/ContraceptionAssessment/ContraceptionAssessment3";
import { ContraceptionAssessment4 } from "./Pages/ContraceptionAssessment/ContraceptionAssessment4";
import { ContraceptionAssessment5 } from "./Pages/ContraceptionAssessment/ContraceptionAssessment5";
import { ContraceptionAssessment6 } from "./Pages/ContraceptionAssessment/ContraceptionAssessment6";
import { ContraceptionAssessment7 } from "./Pages/ContraceptionAssessment/ContraceptionAssessment7";
import { ContraceptionAssessment8 } from "./Pages/ContraceptionAssessment/ContraceptionAssessment8";
import { ContraceptionAssessment9 } from "./Pages/ContraceptionAssessment/ContraceptionAssessment9";

//ONBOARDING
import { Onboarding1 } from "./Pages/Onboarding/Onboarding1";
import { Onboarding2 } from "./Pages/Onboarding/Onboarding2";
import { Onboarding3 } from "./Pages/Onboarding/Onboarding3";

/* import { AllyOrganizations } from './Pages/AllyOrganizations/AllyOrganizations'
import { WomenOnWeb } from './Pages/AllyOrganizations/WomenOnWeb'
import { WomenHelpWomen } from './Pages/AllyOrganizations/WomenHelpWomen'
import { Safe2Choose } from './Pages/AllyOrganizations/Safe2Choose'
import { Mulier } from './Pages/AllyOrganizations/Mulier'
import { InternationalResources } from './Pages/AllyOrganizations/InternationalResources' */

import { TermsOfUseAlert } from "./Pages/TermsOfUse/TermsOfUseAlert";
import { PrivacyPolicyPage } from "./Pages/TermsOfUse/PrivacyPolicyPage";
import { TermsOfUsePage } from "./Pages/TermsOfUse/TermsOfUsePage";
import { IntroPage } from "./Pages/SplashPage/IntroPage";

// RESOURCES
import ResourcesHome from "./Pages/Resources/ResourcesHome";
import EmotionalSupport from "./Pages/Resources/EmotionalSupport";
import FinancialHelp from "./Pages/Resources/FinancialHelp";
import ImmiSupport from "./Pages/Resources/ImmiSupport";
import LegalAssistance from "./Pages/Resources/LegalAssistance";
import MedicalAdvice from "./Pages/Resources/MedicalAdvice";
import MoreInfo from "./Pages/Resources/MoreInfo";
import PillsAndClinics from "./Pages/Resources/PillsAndClinics";
import PlanC from "./Pages/Resources/PlanC";
import Steps from "./Pages/Resources/Steps";

/* Abortion Bucket Pages */

import { UnderConstructionAbortionBucket } from "./Pages/AbortionBuckets/UnderConstructionAbortionBucket";

/* Testimonials */
import { Testimonials } from "./Pages/Testimonials/Testimonials";

/* Language Selection*/
import { LanguageSelectionPage } from "./Pages/LanguageSelection/LanguageSelectionPage";

import ReactGA from "react-ga4";

//this is for the multi-language feature of the app. It will check the url for the language parameter and set the language accordingly. If no language is specified, it will default to es-VE
function getLanguageFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("lang");
}

ReactGA.initialize("G-F6TWXP1ZRG");

// taking out the GA4 code

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataLoaded: false,
      appLanguage: "es-VE", // es-VE, es-US
      datePickerLanguage: "es-419",
      authenticate: { hasPin: false, hasAccess: true },
      firstTimeUser: true,
      reloadHamburgerMenu: false,
    };
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.toggleHamburgerMenuReload = this.toggleHamburgerMenuReload.bind(this);
  }

  //this is passed to onBoarding3 so when the user clicks one of the radio options, it will execute. In onBoarding3, the function is called in the first this.setState function on line 134ish. Double check if this is where we want the state to updated (when the user clicks a radio button)
  firstTimeUserFunction = () => {
    this.setState({
      firstTimeUser: false,
    });

    console.log(this.state.firstTimeUser, "first time update");
  };

  authenticateUser = (hasPinBool, hasAccessBool) => {
    // If the user has a pin and enters their pin on the EnterPin screen, run this function
    this.setState({
      authenticate: { hasPin: hasPinBool, hasAccess: hasAccessBool },
    });
  };

  handleLanguageSelection = (language) => {
    this.setState({ onboardingLanguage: language });
  };
  
  handleLanguageChange(newLanguage) {
    // Update the language in local storage
    localStorage.setItem("language", newLanguage);

    // Update the language in the state
    this.setState({ appLanguage: newLanguage });

    // Redirect the user to the current page with the new language parameter
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("lang", newLanguage);

    // Use history.push to change the route without causing a full page reload
    this.props.history.push(currentUrl.pathname + currentUrl.search);
  }

  toggleHamburgerMenuReload() {
    this.setState((prevState) => ({
      reloadHamburgerMenu: !prevState.reloadHamburgerMenu,
    }));
  }

  async loadContent() {
    // Get the user's language preference from local storage
    const language = localStorage.getItem("language");

    // Include the language in your API call to Contentful
    const response = await fetch(
      `https://cdn.contentful.com/spaces/p7yh4e0gy36m/entries?access_token=XzDee6q7P7ZoEYZ6sHaftcMwBT-PkCK0W3zRWcXaJQs&locale=${language}`
    );

    // Parse the response and update your app's state with the new content
    const content = await response.json();

    // Update your app's state with the fetched content
    this.setState({ content });
  }

  componentDidMount() {
    // ReactGA.initialize('G-F6TWXP1ZRG')

    // ReactGA.set({
    //   'Language': this.state.appLanguage,
    // });

    // ReactGA.event({
    //   category: 'Language Selection',
    //   action: 'Select',
    //   label: this.state.appLanguage,
    // })

    // Initialize GA4
    ReactGA.initialize("G-F6TWXP1ZRG");

    SafeAreaController.injectCSSVariables();

    // Check if a language is stored in local storage
    const languageFromLocalStorage = localStorage.getItem("language");

    // If a language is stored, use it; otherwise, use the default language
    const selectedLanguage = languageFromLocalStorage || "es-VE"; // Replace 'es-VE' with your default language

    // Set the 'Language' custom dimension
    ReactGA.set({
      Language: selectedLanguage,
    });

    // Track the language selection event
    ReactGA.event({
      category: "Language Selection",
      action: "Select",
      label: selectedLanguage,
    });

    if (localStorage.getItem("userPin")) {
      if (sessionStorage.getItem("hasAccess")) {
        this.authenticateUser(true, true);
      } else {
        this.authenticateUser(true, false);
      }
    } else {
      this.authenticateUser(false, true);
    }

    // Get the language from the URL
    const languageFromUrl = getLanguageFromUrl();
    /* console.log('Language from URL:', languageFromUrl); */ // Added console.log here

    // If a language is set in the URL, update the language in local storage and state
    if (languageFromUrl) {
      localStorage.setItem("language", languageFromUrl);
      this.setState({ appLanguage: languageFromUrl });
    } else {
      // If no language is set in the URL, get the language from local storage
      const languageFromLocalStorage = localStorage.getItem("language");
      /* console.log('Language from local storage:', languageFromLocalStorage); */ // Added console.log here

      // If a language is set in local storage, update the language in state
      if (languageFromLocalStorage) {
        this.setState({ appLanguage: languageFromLocalStorage });
      }
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    /* console.log('Current language: ', this.state.appLanguage);
    console.log('Previous language: ', prevState.appLanguage);
    console.log('Language in localStorage: ', localStorage.getItem('language')); */

    if (localStorage.getItem("language") !== prevState.appLanguage) {
      /* console.log('Language in localStorage has changed'); */
    }

    if (this.state.appLanguage !== prevState.appLanguage) {
      await this.loadContent();
    }

    if (this.state.authenticate !== prevState.authenticate) {
      if (this.state.authenticate.hasAccess === true) {
        // Get and load all user data
        if (window.navigator.onLine) {
          // online
          // Initial sync - All Space Entries and Assets
          var getContent = client.sync({
            initial: true, // TODO: ALWAYS TRUE
            resolveLinks: false, // Enable/Disable links from entries to other entries and assets to be resolved
            locale: this.state.appLanguage,
          });
          getContent.then((response) => {
            // console.log('response: ', response)
            // console.log('response.entries: ', response.entries)
            // console.log('response.assets: ', response.assets)
            const responseObj = JSON.parse(response.stringifySafe());
            const entries = responseObj.entries;
            const assets = responseObj.assets;
            // Store entries locally
            localStorage.setItem("ayaAppEntries", JSON.stringify(entries));
            localStorage.setItem("ayaAppAssets", JSON.stringify(assets));
            localStorage.setItem("contentfulPageUrl", response.nextPageUrl);
            localStorage.setItem("contentfulSyncToken", response.nextSyncToken);

            this.setState({
              dataLoaded: true,
            });

            // Preload all contentful images to cache for offline
            const stringifyAssets = JSON.stringify(assets);
            const parseAssets = JSON.parse(stringifyAssets);
            // remove later, just for troubleshooting
            /* console.log(this.state.appLanguage) */

            /* console.log(response) */

            parseAssets.forEach((image) => {
              // remove later, just for troubleshooting
              /* console.log(image.fields.file) */
              if (image.fields.file) {
                const locale = image.fields.file[this.state.appLanguage]
                  ? this.state.appLanguage
                  : image.fields.file["en"]
                  ? "en"
                  : null;
                if (locale) {
                  const img = new Image();
                  img.src = "https:" + image.fields.file[locale].url;
                }
              }
            });
          });
          getContent.catch((err) => {
            console.log(err);
            if (
              localStorage.getItem("ayaAppEntries") &&
              localStorage.getItem("ayaAppAssets")
            ) {
              this.setState({
                dataLoaded: true,
              });
            } else {
              alert("App needs wifi to download initial data");
            }
          });
        } else {
          // offline
          if (
            localStorage.getItem("ayaAppEntries") &&
            localStorage.getItem("ayaAppAssets")
          ) {
            this.setState({
              dataLoaded: true,
            });
          } else {
            alert("App needs wifi to download initial data");
          }
        } // if (window.navigator.onLine)
      } // if (this.state.authenticate.hasPin ...
    } // if (this.state !== prevState) ...
  } // componentDidUpdate

  render() {
    // Has a pin but needs to be authenticated
    return (
      <div className="flexColumn flexGrow1 app">
        {/* <ChatManager /> */}
        <Router basename="/">
          <Route
            path="/enter-pin"
            render={(props) =>
              this.state.authenticate.hasPin ? (
                <>
                  {this.state.authenticate.hasAccess === false ? (
                    <EnterPin
                      {...props}
                      appLanguage={this.state.appLanguage}
                      authenticateUser={this.authenticateUser}
                    />
                  ) : (
                    <Redirect to="/home" />
                  )}
                </>
              ) : (
                <Redirect to="/" />
              )
            }
          />

          <Route
            path="/"
            exact
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <SplashPage
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/home"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Home {...props} appLanguage={this.state.appLanguage}
                    selectedLanguage={this.props.selectedLanguage}  
                    handleLanguageChange={this.props.handleLanguageChange}    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/about-vitala"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AboutVitala
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/language"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Language
                      {...props}
                      appLanguage={this.state.appLanguage}
                      handleLanguageChange={this.handleLanguageChange}
                      toggleHamburgerMenuReload={this.toggleHamburgerMenuReload}
                    />
                  ) : (
                    <></> // Optionally, you can render a loading indicator or keep it empty as you've done
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/privacy-principles"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <PrivacyPrinciples
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/privacy-policy"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <PrivacyPolicy
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/pin-setup"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <PinSetUp {...props} appLanguage={this.state.appLanguage} />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/user-security"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <UserSecurity
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/feedback"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Feedback {...props} appLanguage={this.state.appLanguage} />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/self-care-advice"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <SelfCareAdvice
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/onboarding1"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Onboarding1
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/onboarding2"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Onboarding2
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/onboarding3"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Onboarding3
                      {...props}
                      appLanguage={this.state.appLanguage}
                      firstTimeLogin={this.firstTimeUserFunction}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/abortion"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionStepsMenu
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/abortion-step-1"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionStep1
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/abortion-step-2"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionStep2
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/abortion-step-3"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionStep3
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/abortion-step-4"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionStep4
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/abortion-step-4-pill-regimen-a"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionPillRegimenA
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/abortion-step-4-pill-regimen-b"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionPillRegimenB
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/abortion-step-5"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionStep5
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/abortion-step-6"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionStep6
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/abortion-step-7"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionStep7
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/abortion-step-7-failed"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionStep7Failed
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/abortion-step-7-passed"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionStep7Passed
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/abortion-assessment-q1a"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionAssessment1A
                      {...props}
                      appLanguage={this.state.appLanguage}
                      datePickerLanguage={this.state.datePickerLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/abortion-assessment-q1b"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionAssessment1B
                      {...props}
                      appLanguage={this.state.appLanguage}
                      datePickerLanguage={this.state.datePickerLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/abortion-assessment-q2"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionAssessment2
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/abortion-assessment-q3"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionAssessment3
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/abortion-assessment-q4"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionAssessment4
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          {/* <Route path='/abortion-assessment-q5'
              render={(props) => this.state.authenticate.hasAccess === true ?
              <>
                {this.state.dataLoaded === true ?
                <AbortionAssessment5 {...props} appLanguage={this.state.appLanguage} />
                :
                <></>
                }
                </>
                :
              <Redirect to='/enter-pin' />
            }
            /> */}
          <Route
            path="/abortion-assessment-passed"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionAssessmentPassed
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/abortion-assessment-failed"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionAssessmentFailed
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contraception"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionMenu
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-pills"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionPills
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-copper-iud"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionCopperIud
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-mirena-ius"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionMirenaIus
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-implant"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionImplant
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-injection"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionInjection
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-condoms"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionCondoms
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-tl"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionTl
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-ec"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionEc
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-vasectomia"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionVasectomia
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-fam"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionFam
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-withdrawal"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionWithdrawal
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contraception-cap"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionCap
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contraception-diaphragm"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionDiaphragm
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contraception-patch"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionPatch
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contraception-ring"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionRing
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contraception-assessment"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionAssessment
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-assessment-q1"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionAssessment1
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-assessment-q2"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionAssessment2
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-assessment-q3"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionAssessment3
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-assessment-q4"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionAssessment4
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-assessment-q5"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionAssessment5
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-assessment-q6"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionAssessment6
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-assessment-q7"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionAssessment7
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/contraception-assessment-q8"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionAssessment8
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contraception-assessment-q9"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionAssessment9
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/contraception-assessment-options"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ContraceptionAssessmentOptionsMenu
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          {/*           <Route
            path="/notifications"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <NotificationsInbox
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          /> */}
          {/*           <Route
            path="/notifications-opt-in"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ActivateNotifications
                      {...props}
                      appLanguage={this.state.appLanguage}
                      datePickerLanguage={this.state.datePickerLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          /> */}
          {/*           <Route
            path="/notifications-opt-out"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <DeactivateNotifications
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          /> */}
          {/*         <Route
            path="/notification-3hrs"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Hour3Notification
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          /> */}
          {/*           <Route
            path="/notification-6hrs"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Hour6Notification
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          /> */}
          {/*           <Route
            path="/notification-36hrs"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Hour36Notification
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          /> */}
          {/*           <Route
            path="/notification-72hrs"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Hour72Notification
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          /> */}
          {/*          <Route
            path="/notification-8days"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Day8Notification
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          /> */}
          {/*           <Route
            path="/notification-15days"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Day15Notification
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          /> */}
          {/*           <Route
            path="/notification-30days"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Day30Notification
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          /> */}

          {/*           <Route
            path="/ally-organizations"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AllyOrganizations
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/women-on-web"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <WomenOnWeb
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/women-help-women"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <WomenHelpWomen
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/safe2choose"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Safe2Choose
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/mulier"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Mulier {...props} appLanguage={this.state.appLanguage} />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />
          <Route
            path="/recursos-internacionales-sobre-la-atención-integral-del-aborto"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <InternationalResources
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          /> */}

          {/*           <Route
            path="/abortion-support-pills"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionBucketMenuPills
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          /> */}
          {/* 
          <Route
            path="/abortion-support-surgical"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionBucketMenuSurgical
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          /> */}

          {/*           <Route
            path="/abortion-support-surgical-faq"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionBucketSurgicalFAQ
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />  */}

          {/*           <Route
            path="/abortion-support-pills-faq"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionBucketPillsFAQ
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          /> */}

          {/*           <Route
            path="/abortion-support-pills-before"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionBucketPillsBefore
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />  */}

          {/*           <Route
            path="/abortion-support-pills-during"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionBucketPillsDuring
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />  */}

          {/*           <Route
            path="/abortion-support-pills-after"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionBucketPillsAfter
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />  */}

          {/*           <Route
            path="/abortion-support-surgical-before"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionBucketSurgicalBefore
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />  */}

          {/*           <Route
            path="/abortion-support-surgical-during"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionBucketSurgicalDuring
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          /> */}

          {/*           <Route
            path="/abortion-support-surgical-after"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <AbortionBucketSurgicalAfter
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />   */}

          {/*         <Route
          path="/abortion-support-pills-all"
          render={(props) =>
            this.state.authenticate.hasAccess === true ? (
              <>
                {this.state.dataLoaded === true ? (
                  <AbortionBucketPillsAll
                    {...props}
                    appLanguage={this.state.appLanguage}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Redirect to="/enter-pin" />
            )
          }
        /> */}

          {/*         <Route
          path="/abortion-support-surgical-all"
          render={(props) =>
            this.state.authenticate.hasAccess === true ? (
              <>
                {this.state.dataLoaded === true ? (
                  <AbortionBucketSurgicalAll
                    {...props}
                    appLanguage={this.state.appLanguage}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Redirect to="/enter-pin" />
            )
          }
        /> */}

          {/*         <Route
          path="/surgical-procedure-about"
          render={(props) =>
            this.state.authenticate.hasAccess === true ? (
              <>
                {this.state.dataLoaded === true ? (
                  <AbortionBucketAboutSurgicalProcedure
                    {...props}
                    appLanguage={this.state.appLanguage}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Redirect to="/enter-pin" />
            )
          }
        /> */}

          <Route
            path="/page-under-construction"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <UnderConstructionAbortionBucket
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/resources"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ResourcesHome
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/resources-legal-assistance"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <LegalAssistance
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/resources-pills-and-clinics"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <PillsAndClinics
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/resources-medical-advice"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <MedicalAdvice
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/resources-support"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <ImmiSupport
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/resources-plan-c"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <PlanC {...props} appLanguage={this.state.appLanguage} />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/resources-financial-help"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <FinancialHelp
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/resources-more-info"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <MoreInfo {...props} appLanguage={this.state.appLanguage} />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/resources-emotional-support"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <EmotionalSupport
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/resources-steps"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Steps {...props} appLanguage={this.state.appLanguage} />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/testimonials"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <Testimonials
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/TermsOfUseAlert"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <TermsOfUseAlert
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/IntroPage"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <IntroPage
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/PrivacyPolicyPage"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <PrivacyPolicyPage
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/TermsOfUsePage"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <TermsOfUsePage
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          />

          <Route
            path="/language-selection"
            render={(props) => (
              <LanguageSelectionPage
                {...props}
                handleLanguageChange={this.handleLanguageChange}
              />
            )}
          />

          {/* <Route
            path="/new-home"
            render={(props) =>
              this.state.authenticate.hasAccess === true ? (
                <>
                  {this.state.dataLoaded === true ? (
                    <NewHomeMenu
                      {...props}
                      appLanguage={this.state.appLanguage}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Redirect to="/enter-pin" />
              )
            }
          /> */}
        </Router>
      </div>
    );
  }
}

/* export default App */
export default withRouter(App);
