import './MedicalAdvice.modules.css'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';
import BasicCard from '../../Components/BasicCard'
import { useState, useEffect } from 'react'
import  { ResourcesTopNav }  from '../../Components/ResourcesTopNav';
import { BottomNavBar } from '../../Components/BottomNavBar'
import * as ContentfulMarkdown from 'react-markdown'
import ReactGA from 'react-ga4';


function MedicalAdvice(props) {
	const [pageTitleOne, setPageTitleOne] = useState('')
	const [pageImageOne, setPageImageOne] = useState('')
	const [pageDescriptionOne, setPageDescriptionOne] = useState('')
	const [helpLineButton, setHelpLineButton] = useState('')
	const [cardOneTitle, setCardOneTitle] = useState('')
	const [cardOneDescription, setCardOneDescription] = useState('')
	const [cardOneLogo, setCardOneLogo] = useState('')
	const [buttonOne, setButtonOne] = useState('')
	const [buttonTwo, setButtonTwo] = useState('')
	const [phoneIcon, setPhoneIcon] = useState('');
	const [arrowIconOne, setArrowIconOne] = useState('');
	const [selectedItem, setSelectedItem] = useState(null);
	const [navButtonOne, setNavButtonOne] = useState('')
	const [navButtonTwo, setNavButtonTwo] = useState('')
	const [navButtonThree, setNavButtonThree] = useState('')
	const [navButtonFour, setNavButtonFour] = useState('')
	const [navButtonFive, setNavButtonFive] = useState('')
	const [navButtonSix, setNavButtonSix] = useState('')

	const location = useLocation();
  const currentPath = location.pathname;

	const items = [
		{ name: navButtonThree, path: "/resources-medical-advice"},
		{ name: navButtonFour, path: "/resources-financial-help", backgroundColor: "#FFF5E2" },
		{ name: navButtonFive, path: "/resources-emotional-support", backgroundColor: "#F6EFFF" },
		{ name: navButtonSix, path: "/resources-support", backgroundColor: "#EFF9F4" },
		{ name: navButtonOne, path: "/resources-legal-assistance", backgroundColor: "#E5F1FA" },
		{ name: navButtonTwo, path: "/resources-pills-and-clinics", backgroundColor: "#FAE7E7" },
	  ];

	  const settings = {
		dots: false,
		infinite: true,
		speed: 200,
		arrows: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: true,
  		centerPadding: '5px', 
		variableWidth: true,
		beforeChange: (current, next) => setSelectedItem(items[next].name),
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				centerPadding: '10px',
			  },
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				initialSlide: 2
				/* centerPadding: '5px', */
			  },
			},
		  ]
	};


	useEffect(()=> {
	// GA4 code
	ReactGA.send("/resources-medical-advice");
	//this makes sure the page always scrolls to the top of the page when rendered
	window.scrollTo(0, 0); 

	document.title = "Resources Medical Advice";
	
        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

		// GET ALL ENTRIES 
		const getEntries = localStorage.getItem('ayaAppEntries');
    const entries = JSON.parse(getEntries);

		// GET MODEL
		const getModel = entries.filter(
      (obj) => obj.sys.contentType.sys.id === "resourcesCategory"
    );
		
		// GET CONTENT
		const getContent = getModel.filter(
		(obj) => obj.sys.id === "6fk2geOecVScm1NRsfcV22"
		)


		// GET PAGE TITLE
		let pageTitle = ''
			if (getContent?.length > 0) {
				const pageTitleData = getContent[0].fields;
				pageTitle = pageTitleData.title? pageTitleData.title[props.appLanguage] : '';
			}
		setPageTitleOne(pageTitle);
		

		
			// GET NAVITEM ONE
			let navItem1 = ''
			if (getContent?.length > 0) {
				const navItemOneData = getContent[0].fields;
				navItem1 = navItemOneData.topNavButton1? navItemOneData.topNavButton1[props.appLanguage] : '';
      }
			setNavButtonOne(navItem1);


			
		// GET NAVITEM TWO
		let navItem2 = ''
		if (getContent?.length > 0) {
			const navItemTwoData = getContent[0].fields;
			navItem2 = navItemTwoData.topNavButton2? navItemTwoData.topNavButton2[props.appLanguage] : '';
		}
		setNavButtonTwo(navItem2);



		// GET NAVITEM THREE
		let navItem3 = ''
		if (getContent?.length > 0) {
			const navItemThreeData = getContent[0].fields;
			navItem3 = navItemThreeData.topNavButton3? navItemThreeData.topNavButton3[props.appLanguage] : '';
		}
		setNavButtonThree(navItem3);



		// GET NAVITEM FOUR
		let navItem4 = ''
		if (getContent?.length > 0) {
			const navItemFourData = getContent[0].fields;
			navItem4 = navItemFourData.topNavButton4? navItemFourData.topNavButton4[props.appLanguage] : '';
		}
		setNavButtonFour(navItem4);



		// GET NAVITEM FIVE 
		let navItem5 = ''
		if (getContent?.length > 0) {
			const navItemFiveData = getContent[0].fields;
			navItem5 = navItemFiveData.topNavButton5? navItemFiveData.topNavButton5[props.appLanguage] : '';
		}
		setNavButtonFive(navItem5);



		// GET NAVITEM SIX
		let navItem6 = ''
		if (getContent?.length > 0) {
			const navItemSixData = getContent[0].fields;
			navItem6 = navItemSixData.topNavButton6? navItemSixData.topNavButton6[props.appLanguage] : '';
		}
		setNavButtonSix(navItem6);


		
		// GET PAGE DESCRIPTION
		let pageDescription = ''
			if (getContent?.length > 0) {
				const pageDescriptionData = getContent[0].fields;
				pageDescription = pageDescriptionData.description? pageDescriptionData.description[props.appLanguage] : '';
			}
		setPageDescriptionOne(pageDescription);



		// GET HELPLINE ACCESS BUTTON text
		let helplineAccessButton = ''
			if (getContent?.length > 0) {
				const helpLineAccessButtonData = getContent[0].fields;
				helplineAccessButton = helpLineAccessButtonData.helplineAccessButton? helpLineAccessButtonData.helplineAccessButton[props.appLanguage] : '';
			}
		setHelpLineButton(helplineAccessButton);


		// GET CARD ONE title
		let cardOneTitleText = ''
			if (getContent?.length > 0) {
				const cardOneTitleData = getContent[0].fields;
				cardOneTitleText = cardOneTitleData.card1Title? cardOneTitleData.card1Title[props.appLanguage] : '';
			}
		setCardOneTitle(cardOneTitleText);


	
		// GET CARD ONE DESCRIPTION
		let cardOneDescriptionText = ''
			if (getContent?.length > 0) {
				const cardOneDescriptionData = getContent[0].fields;
				cardOneDescriptionText = cardOneDescriptionData.card1Description? cardOneDescriptionData.card1Description[props.appLanguage] : '';
			}
		setCardOneDescription(cardOneDescriptionText);


		// GET CARD ONE button one
		let button1 = ''
		if (getContent?.length > 0) {
			const buttonOneData = getContent[0].fields;
			button1 = buttonOneData.card1Button1? buttonOneData.card1Button1[props.appLanguage] : '';
		}
		setButtonOne(button1);



		// GET CARD ONE button two
		let button2 = ''
		if (getContent?.length > 0) {
			const buttonTwoData = getContent[0].fields;
			button2 = buttonTwoData.card1Button2? buttonTwoData.card1Button2[props.appLanguage] : '';
		}
		setButtonTwo(button2);



		// ASSETS
		// GET MAIN PAGE image
			const getAssets = localStorage.getItem('ayaAppAssets');
			const assets = JSON.parse(getAssets);
	
			const getImages = assets.filter(
				obj => obj.sys.id === '4bTcz5KqXTg9MPZKCECU5X'
			);
	
			let imageOne = '';
			if (getImages?.length > 0 && getImages[0]?.fields?.file?.[props.appLanguage]?.url) {
				imageOne = 'https:' + getImages[0].fields.file[props.appLanguage].url;
			}
			setPageImageOne(imageOne)




		// GET CARD ONE LOGO
		const getCardOneLogo = assets.filter(
      obj => obj.sys.id === '5wnW4IgmOXN7k8lotAwPTN'
    );

    let logoOne = '';
    if (getCardOneLogo?.length > 0 && getCardOneLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
			logoOne = 'https:' + getCardOneLogo[0].fields.file[props.appLanguage].url;
		}
    setCardOneLogo(logoOne)



		// GET ARROW ICON
		const getArrowIcon = assets.filter(
      obj => obj.sys.id === '3Xegltxk8ROHntvPdStc3A'
    );

    let arrowIcon = '';
    if (getArrowIcon?.length > 0 && getArrowIcon[0]?.fields?.file?.[props.appLanguage]?.url) {
			arrowIcon = 'https:' + getArrowIcon[0].fields.file[props.appLanguage].url;
		}
    setArrowIconOne(arrowIcon);



		// GET PHONE ICON
		const getPhoneIcon = assets.filter(
      obj => obj.sys.id === '2ROiC1rzhVlik9fUki9smc'
    );

    let phoneIconOne = '';
    if (getPhoneIcon?.length > 0 && getPhoneIcon[0]?.fields?.file?.[props.appLanguage]?.url) {
			phoneIconOne = 'https:' + getPhoneIcon[0].fields.file[props.appLanguage].url;
		}
    setPhoneIcon(phoneIconOne);

		
		
	}, [props.appLanguage])
	

	const language = props.appLanguage;
	let linkOne;
  if (language === 'en') {
    linkOne = 'https://www.mahotline.org/';
  } else if (language === 'es-US') {
    linkOne = 'https://es.mahotline.org/';
  }


	return (
		<>
		<ResourcesTopNav bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...props} appLanguage={props.appLanguage} />
		
		<div className="carousel-container">
            <div className="dots-container">
            </div>
						<div className={`custom-dot-container`}>
                <Slider {...settings}>
                    {items.map((item, index) => (
                    <div key={index} className="slide-item">
                    <Link
                    to={item.path}
                    className={`link-button custom-dot ${item.path === currentPath ? "active" : ""}`}
                    style={{ backgroundColor: item.backgroundColor }} // Apply the background color of the buttons here
                    >
                    {item.name}
                    </Link>
                    </div>
                    ))}
                </Slider>
							</div>
            </div>

		<div className= 'medicalAdviceHomeContainer' style={{ backgroundColor: 'white' }}>

			<div className='medical-advice-title'>{pageTitleOne}</div>
			<img src={pageImageOne} alt='medical' />

			<div style={{maxWidth: '340px'}}>
			<div className='medical-advice-page-description'>	{pageDescriptionOne}</div>
					<div className='help-line-container'>
								<a
									href='tel:8448682812'
									style={{
										textDecoration: 'none', 
										color: 'inherit', 
										display: 'flex', 
										alignItems: 'center', 
									}}
								>
									{helpLineButton}
									<div className='phone-icon-container'>
										<img className='phone-icon' src={phoneIcon} alt='phone icon' />
									</div>
								</a>
				</div>
			</div>
		
			<div className='cardsContainer'>
					<a
						href={linkOne}
						target="_blank"
						rel="noopener noreferrer"
						style={{
							color: 'inherit',
							textDecoration: 'none',
						}}
					>
						<BasicCard
							image={cardOneLogo}
							title={
								<div style={{ paddingTop: '8px' }}>
									{cardOneTitle}
								</div>
							}
							content={
								<div style={{ paddingBottom: 'px' }}>
									{cardOneDescription}
								</div>
							}
							button1={buttonOne}
							button2={
								<div style={{ textAlign: 'left' }}>
									<a href="tel:8332462632" style={{ textDecoration: 'none', color: '#051D58' }}>
										{buttonTwo}
									</a>
								</div>
							}
							arrowIconOne={arrowIconOne}
							phoneIcon={phoneIcon}
						/>
					</a>
			</div>
			
			<div className='end-of-page'></div>
			<BottomNavBar appLanguage={props.appLanguage} />
		</div>
		</>
	)
}

export default MedicalAdvice;


