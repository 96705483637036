import './PillsAndClinics.modules.css'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from 'react-router-dom';
import BasicCard from '../../Components/BasicCard'
import { useState, useEffect } from 'react';
import { BottomNavBar } from '../../Components/BottomNavBar'
import  { ResourcesTopNav }  from '../../Components/ResourcesTopNav';
import ReactGA from 'react-ga4';




function PillsAndClinics(props) {
	const [pageTitleOne, setPageTitleOne] = useState('')
	const [pageImageOne, setPageImageOne] = useState('')
	const [pageDescriptionOne, setPageDescriptionOne] = useState('')
	const [helpLineButton, setHelpLineButton] = useState('')
	const [subTitleOne, setSubTitleOne] = useState('')
	const [subTitleTwo, setSubTitleTwo] = useState('')
	const [subTitleThree, setSubTitleThree] = useState('')
	const [cardOneLogo, setCardOneLogo] = useState('')
	const [cardTwoLogo, setCardTwoLogo] = useState('')
	const [cardThreeLogo, setCardThreeLogo] = useState('')
	const [cardFourLogo, setCardFourLogo] = useState('')
	const [cardFiveLogo, setCardFiveLogo] = useState('')
	const [cardSixLogo, setCardSixLogo] = useState('')
	const [cardSevenLogo, setCardSevenLogo] = useState('')
	const [cardOneTitle, setCardOneTitle] = useState('')
	const [cardTwoTitle, setCardTwoTitle] = useState('')
	const [cardThreeTitle, setCardThreeTitle] = useState('')
	const [cardFourTitle, setCardFourTitle] = useState('')
	const [cardFiveTitle, setCardFiveTitle] = useState('')
	const [cardSixTitle, setCardSixTitle] = useState('')
	const [cardSevenTitle, setCardSevenTitle] = useState('')
	const [cardOneDescription, setCardOneDescription] = useState('')
	const [cardTwoDescription, setCardTwoDescription] = useState('')
	const [cardThreeDescription, setCardThreeDescription] = useState('')
	const [cardFourDescription, setCardFourDescription] = useState('')
	const [cardFiveDescription, setCardFiveDescription] = useState('')
	const [cardSixDescription, setCardSixDescription] = useState('')
	const [cardSevenDescription, setCardSevenDescription] = useState('')
	const [phoneIcon, setPhoneIcon] = useState('');
	const [arrowIconTwo, setArrowIconTwo] = useState('');
	const [selectedItem, setSelectedItem] = useState(null);
	const [navButtonOne, setNavButtonOne] = useState('')
	const [navButtonTwo, setNavButtonTwo] = useState('')
	const [navButtonThree, setNavButtonThree] = useState('')
	const [navButtonFour, setNavButtonFour] = useState('')
	const [navButtonFive, setNavButtonFive] = useState('')
	const [navButtonSix, setNavButtonSix] = useState('')
	const [lastButton, setLastButton] = useState('');
	const [lastButtonArrow, setLastButtonArrow] = useState('');

	const location = useLocation();
  	const currentPath = location.pathname;

	const items = [
		{ name: navButtonTwo, path: "/resources-pills-and-clinics"},
		{ name: navButtonThree, path: "/resources-medical-advice", backgroundColor: "#FFEBFF" },
		{ name: navButtonFour, path: "/resources-financial-help", backgroundColor: "#FFF5E2" },
		{ name: navButtonFive, path: "/resources-emotional-support", backgroundColor: "#F6EFFF"},
		{ name: navButtonSix, path: "/resources-support", backgroundColor: "#EFF9F4" },
		{ name: navButtonOne, path: "/resources-legal-assistance", backgroundColor: "#E5F1FA" },
	  ];

	  const settings = {
		dots: false,
		infinite: true,
		speed: 200,
		arrows: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: true,
  		centerPadding: '5px', 
		variableWidth: true,
		beforeChange: (current, next) => setSelectedItem(items[next].name),
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				centerPadding: '10px',
			  },
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				initialSlide: 2
				/* centerPadding: '5px', */
			  },
			},
		  ]
	};

	useEffect(() => {
	// GA4 code
	ReactGA.send("/resources-pills-and-clinics");
	//this makes sure the page always scrolls to the top of the page when rendered
	window.scrollTo(0, 0); 

	document.title = "Resources Pills and Clinics";
	
        // this hides the Crisp Chat 
        /* $crisp.push(["do", "chat:hide"]) */

		// GET ALL ENTRIES 
		const getEntries = localStorage.getItem('ayaAppEntries');
    const entries = JSON.parse(getEntries);


		// GET MODEL
		const getModel = entries.filter(
      (obj) => obj.sys.contentType.sys.id === "resourcesCategory"
    );
		
		// GET CONTENT
		const getContent = getModel.filter(
		(obj) => obj.sys.id === "7iKacNsk1zs5AHV9GhEznj"
		)


		// GET NAVITEM ONE
		let navItem1 = ''
			if (getContent?.length > 0) {
				const navItemOneData = getContent[0].fields;
				navItem1 = navItemOneData.topNavButton1? navItemOneData.topNavButton1[props.appLanguage] : '';
      }
			setNavButtonOne(navItem1);


			
		// GET NAVITEM TWO
		let navItem2 = ''
		if (getContent?.length > 0) {
			const navItemTwoData = getContent[0].fields;
			navItem2 = navItemTwoData.topNavButton2? navItemTwoData.topNavButton2[props.appLanguage] : '';
		}
		setNavButtonTwo(navItem2);



		// GET NAVITEM THREE
		let navItem3 = ''
		if (getContent?.length > 0) {
			const navItemThreeData = getContent[0].fields;
			navItem3 = navItemThreeData.topNavButton3? navItemThreeData.topNavButton3[props.appLanguage] : '';
		}
		setNavButtonThree(navItem3);



		// GET NAVITEM FOUR
		let navItem4 = ''
		if (getContent?.length > 0) {
			const navItemFourData = getContent[0].fields;
			navItem4 = navItemFourData.topNavButton4? navItemFourData.topNavButton4[props.appLanguage] : '';
		}
		setNavButtonFour(navItem4);



		// GET NAVITEM FIVE 
		let navItem5 = ''
		if (getContent?.length > 0) {
			const navItemFiveData = getContent[0].fields;
			navItem5 = navItemFiveData.topNavButton5? navItemFiveData.topNavButton5[props.appLanguage] : '';
		}
		setNavButtonFive(navItem5);



		// GET NAVITEM SIX
		let navItem6 = ''
		if (getContent?.length > 0) {
			const navItemSixData = getContent[0].fields;
			navItem6 = navItemSixData.topNavButton6? navItemSixData.topNavButton6[props.appLanguage] : '';
		}
		setNavButtonSix(navItem6);



		// GET PAGE title
		let pageTitle = ''
			if (getContent?.length > 0) {
				const pageTitleData = getContent[0].fields;
				pageTitle = pageTitleData.title? pageTitleData.title[props.appLanguage] : '';
      }
			setPageTitleOne(pageTitle);



		// GET PAGE description
			let pageDescription = ''
			if (getContent?.length > 0) {
				const pageDescriptionData = getContent[0].fields;
				pageDescription = pageDescriptionData.description? pageDescriptionData.description[props.appLanguage] : '';
			}
			setPageDescriptionOne(pageDescription);



		// GET HELPLINE ACCESS button text 
			let helplineAccessButton = ''
			if (getContent?.length > 0) {
				const helpLineAccessButtonData = getContent[0].fields;
				helplineAccessButton = helpLineAccessButtonData.helplineAccessButton? helpLineAccessButtonData.helplineAccessButton[props.appLanguage] : '';
			}
			setHelpLineButton(helplineAccessButton);



		// GET SUBTITLE ONE
			let subtitleOneText = ''
			if (getContent?.length > 0) {
				const subTitleOneData = getContent[0].fields;
				subtitleOneText = subTitleOneData.subtitle? subTitleOneData.subtitle[props.appLanguage] : '';
			}
			setSubTitleOne(subtitleOneText);



		// GET SUBTITLE TWO
			let subtitleTwo = ''
			if (getContent?.length > 0) {
				const subTitleTwoData = getContent[0].fields;
				subtitleTwo = subTitleTwoData.subtitle2? subTitleTwoData.subtitle2[props.appLanguage] : '';
			}
			setSubTitleTwo(subtitleTwo);



		// GET SUBTITLE THREE
			let subtitleThree = ''
			if (getContent?.length > 0) {
				const subTitleThreeData = getContent[0].fields;
				subtitleThree = subTitleThreeData.subtitle2? subTitleThreeData.subtitle3[props.appLanguage] : '';
			}
			setSubTitleThree(subtitleThree);

		
		
		// GET CARD ONE title
			let cardOneTitleText = ''
			if (getContent?.length > 0) {
				const cardOneTitleData = getContent[0].fields;
				cardOneTitleText = cardOneTitleData.card1Title? cardOneTitleData.card1Title[props.appLanguage] : '';
			}
			setCardOneTitle(cardOneTitleText);
	


		// GET CARD ONE description
			let cardOneDescriptionText = ''
			if (getContent?.length > 0) {
				const cardOneDescriptionData = getContent[0].fields;
				cardOneDescriptionText = cardOneDescriptionData.card1Description? cardOneDescriptionData.card1Description[props.appLanguage] : '';
			}
			setCardOneDescription(cardOneDescriptionText);



		// GET CARD TWO title
			let cardTwoTitleText = ''
			if (getContent?.length > 0) {
				const cardTwoTitleData = getContent[0].fields;
				cardTwoTitleText = cardTwoTitleData.card2Title? cardTwoTitleData.card2Title[props.appLanguage] : '';
			}
			setCardTwoTitle(cardTwoTitleText);



		// GET CARD TWO description
			let cardTwoDescriptionText = ''
			if (getContent?.length > 0) {
				const cardTwoDescriptionData = getContent[0].fields;
				cardTwoDescriptionText = cardTwoDescriptionData.card2Description? cardTwoDescriptionData.card2Description[props.appLanguage] : '';
			}
			setCardTwoDescription(cardTwoDescriptionText);



		// GET CARD THREE title
			let cardThreeTitleText = ''
			if (getContent?.length > 0) {
				const cardThreeTitleData = getContent[0].fields;
				cardThreeTitleText = cardThreeTitleData.card3Title? cardThreeTitleData.card3Title[props.appLanguage] : '';
			}
			setCardThreeTitle(cardThreeTitleText);



		// GET CARD THREE description
			let cardThreeDescriptionText = ''
			if (getContent?.length > 0) {
				const cardThreeDescriptionData = getContent[0].fields;
				cardThreeDescriptionText = cardThreeDescriptionData.card3Description? cardThreeDescriptionData.card3Description[props.appLanguage] : '';
			}
			setCardThreeDescription(cardThreeDescriptionText);



		// GET CARD FOUR title
			let cardFourTitleText = ''
			if (getContent?.length > 0) {
				const cardFourTitleData = getContent[0].fields;
				cardFourTitleText = cardFourTitleData.card4Title? cardFourTitleData.card4Title[props.appLanguage] : '';
			}
			setCardFourTitle(cardFourTitleText);



		// GET CARD FOUR description
			let cardFourDescriptionText = ''
			if (getContent?.length > 0) {
				const cardFourDescriptionData = getContent[0].fields;
				cardFourDescriptionText = cardFourDescriptionData.card4Description? cardFourDescriptionData.card4Description[props.appLanguage] : '';
			}
			setCardFourDescription(cardFourDescriptionText);



		// GET CARD FIVE title
			let cardFiveTitleText = ''
			if (getContent?.length > 0) {
				const cardFiveTitleData = getContent[0].fields;
				cardFiveTitleText = cardFiveTitleData.card5Title? cardFiveTitleData.card5Title[props.appLanguage] : '';
			}
			setCardFiveTitle(cardFiveTitleText);



		// GET CARD FIVE description
			let cardFiveDescriptionText = ''
			if (getContent?.length > 0) {
				const cardFiveDescriptionData = getContent[0].fields;
				cardFiveDescriptionText = cardFiveDescriptionData.card5Description? cardFiveDescriptionData.card5Description[props.appLanguage] : '';
			}
			setCardFiveDescription(cardFiveDescriptionText);



		// GET CARD SIX title
			let cardSixTitleText = ''
			if (getContent?.length > 0) {
				const cardSixTitleData = getContent[0].fields;
				cardSixTitleText = cardSixTitleData.card6Title? cardSixTitleData.card6Title[props.appLanguage] : '';
			}
			setCardSixTitle(cardSixTitleText);



		// GET CARD SIX description
			let cardSixDescriptionText = ''
			if (getContent?.length > 0) {
				const cardSixDescriptionData = getContent[0].fields;
				cardSixDescriptionText = cardSixDescriptionData.card6Description? cardSixDescriptionData.card6Description[props.appLanguage] : '';
			}
			setCardSixDescription(cardSixDescriptionText);

					// GET CARD SEVEN title
					let cardSevenTitleText = ''
					if (getContent?.length > 0) {
						const cardSevenTitleData = getContent[0].fields;
						cardSevenTitleText = cardSevenTitleData.card7Title? cardSevenTitleData.card7Title[props.appLanguage] : '';
					}
					setCardSevenTitle(cardSevenTitleText);
		
		
		
				// GET CARD SEVEN description
					let cardSevenDescriptionText = ''
					if (getContent?.length > 0) {
						const cardSevenDescriptionData = getContent[0].fields;
						cardSevenDescriptionText = cardSevenDescriptionData.card7Description? cardSevenDescriptionData.card7Description[props.appLanguage] : '';
					}
					setCardSevenDescription(cardSevenDescriptionText);



		// GET LAST BUTTON TEXT 
		let lastButtonOne = ''
		if (getContent?.length > 0) {
			const lastButtonData = getContent[0].fields;
			lastButtonOne = lastButtonData.lastButton? lastButtonData.lastButton[props.appLanguage] : '';
		}
		setLastButton(lastButtonOne);
		



		// ASSETS
		const getAssets = localStorage.getItem('ayaAppAssets');
    const assets = JSON.parse(getAssets);


		// GET MAIN PAGE image
    const getImages = assets.filter(
      obj => obj.sys.id === '2ZCe9Ryu5ppu3GxI0aNgFM'
    );

    let imageOne = '';
    if (getImages?.length > 0 && getImages[0]?.fields?.file?.[props.appLanguage]?.url) {
			imageOne = 'https:' + getImages[0].fields.file[props.appLanguage].url;
		}
    setPageImageOne(imageOne)



		// GET CARD logo ONE
		const getCardOneLogo = assets.filter(
      obj => obj.sys.id === '1TEU8tRVMCr5beGqBHqWKR'
    );

    let logoOne = '';
    if (getCardOneLogo?.length > 0 && getCardOneLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
			logoOne = 'https:' + getCardOneLogo[0].fields.file[props.appLanguage].url;
		}
    setCardOneLogo(logoOne)



		// GET CARD logo TWO 
		const getCardTwoLogo = assets.filter(
      obj => obj.sys.id === '5Vl2MT1ZUF1u1wj5r4p1XY'
    );

    let logoTwo = '';
    if (getCardTwoLogo?.length > 0 && getCardTwoLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
			logoTwo = 'https:' + getCardTwoLogo[0].fields.file[props.appLanguage].url;
		}
    setCardTwoLogo(logoTwo)


		
		// GET CARD logo THREE
		const getCardThreeLogo = assets.filter(
			obj => obj.sys.id === '7KHP3Sht7GmU12OnGqgefu'
		);
	
		let logoThree = '';
		if (getCardThreeLogo?.length > 0 && getCardThreeLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
			logoThree = 'https:' + getCardThreeLogo[0].fields.file[props.appLanguage].url;
		}
		setCardThreeLogo(logoThree)
	
	
	
		// GET CARD logo FOUR
		const getCardFourLogo = assets.filter(
			obj => obj.sys.id === '4D89F13P3iYGjG9I8c7OgO'
		);
	
		let logoFour = '';
		if (getCardFourLogo?.length > 0 && getCardFourLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
			logoFour = 'https:' + getCardFourLogo[0].fields.file[props.appLanguage].url;
		}
		setCardFourLogo(logoFour)



		// GET CARD logo FIVE
		const getCardFiveLogo = assets.filter(
			obj => obj.sys.id === '2bHkbbh6dNoARBTVu3EjFW'
		);

		let logoFive = '';
		if (getCardFiveLogo?.length > 0 && getCardFiveLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
			logoFive = 'https:' + getCardFiveLogo[0].fields.file[props.appLanguage].url;
		}
		setCardFiveLogo(logoFive)
	
	
	
		// GET CARD logo SIX
		const getCardSixLogo = assets.filter(
			obj => obj.sys.id === 'HjpuSPOkQe9xDC2kciFOT'
		);
	
		let logoSix = '';
		if (getCardSixLogo?.length > 0 && getCardSixLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
			logoSix = 'https:' + getCardSixLogo[0].fields.file[props.appLanguage].url;
		}
		setCardSixLogo(logoSix)

				// GET CARD logo SEVEN
				const getCardSevenLogo = assets.filter(
					obj => obj.sys.id === '6k6Flczvl9WFtZuS7Tnecl'
				);
			
				let logoSeven = '';
				if (getCardSevenLogo?.length > 0 && getCardSevenLogo[0]?.fields?.file?.[props.appLanguage]?.url) {
					logoSeven = 'https:' + getCardSevenLogo[0].fields.file[props.appLanguage].url;
				}
				setCardSevenLogo(logoSeven)



		// GET PHONE ICON
		const getPhoneIcon = assets.filter(
      obj => obj.sys.id === '2ROiC1rzhVlik9fUki9smc'
    );

    let phoneIconOne = '';
    if (getPhoneIcon?.length > 0 && getPhoneIcon[0]?.fields?.file?.[props.appLanguage]?.url) {
			phoneIconOne = 'https:' + getPhoneIcon[0].fields.file[props.appLanguage].url;
		}
    setPhoneIcon(phoneIconOne);
		


		// GET ARROW ICON TWO 
		const getArrowIconTwo = assets.filter(
      obj => obj.sys.id === '3Xegltxk8ROHntvPdStc3A'
    );

    let arrowIconTwo = '';
    if (getArrowIconTwo?.length > 0 && getArrowIconTwo[0]?.fields?.file?.[props.appLanguage]?.url) {
			arrowIconTwo = 'https:' + getArrowIconTwo[0].fields.file[props.appLanguage].url;
		}
    setArrowIconTwo(arrowIconTwo);



		// GET WHITE ARROW ICON (last Button arrow)
		const getWhiteArrowIcon = assets.filter(
      obj => obj.sys.id === '1H7uB5gW6dKQU9jin0AmK6'
    );

    let whiteArrowIcon = '';
    if (getWhiteArrowIcon?.length > 0 && getWhiteArrowIcon[0]?.fields?.file?.[props.appLanguage]?.url) {
			whiteArrowIcon = 'https:' + getWhiteArrowIcon[0].fields.file[props.appLanguage].url;
		}
    setLastButtonArrow(whiteArrowIcon);



	}, [props.appLanguage])



	const language = props.appLanguage;
	let linkOne;
  if (language === 'en') {
    linkOne = 'https://www.plannedparenthood.org';
  } else if (language === 'es-US') {
    linkOne = 'https://www.plannedparenthood.org/es';
  }


	let linkTwo;
  if (language === 'en') {
    linkTwo = 'https://www.plancpills.org/';
  } else if (language === 'es-US') {
    linkTwo = 'https://es.plancpills.org/';
  }


	let linkThree;
  if (language === 'en') {
    linkThree = 'https://aidaccess.org/en/';
  } else if (language === 'es-US') {
    linkThree = 'https://aidaccess.org/es/';
  }


	let linkFour;
  if (language === 'en') {
    linkFour = 'https://www.ineedana.com';
  } else if (language === 'es-US') {
    linkFour = 'https://www.ineedana.com/es';
  }


	let linkFive;
  if (language === 'en') {
    linkFive = 'https://www.abortionfinder.org';
  } else if (language === 'es-US') {
    linkFive = 'https://www.abortionfinder.org/es';
  }

  let linkSix;
  if (language === 'en') {
    linkSix = 'https://www.abuzzhealth.com/';
  } else if (language === 'es-US') {
    linkSix = 'https://www.abuzzhealth.com/es/'
  }

  let linkSeven;
  if (language === 'en') {
    linkSeven = 'https://www.abortionpillsinprivate.com/';
  } else if (language === 'es-US') {
    linkSeven = 'https://www.abortionpillsinprivate.com/'
  }

	return (
		<>
		<ResourcesTopNav bgColor="#fff" showHamburgerMenu={true} menuIconColor={'blue'} logoColor={'peach'} bellColor={'blue'} newNotificationColor={'peach'} {...props} appLanguage={props.appLanguage} />
		<div className="carousel-container">
            <div className="dots-container">
            </div>
						<div className={`custom-dot-container`}>
                <Slider {...settings}>
                    {items.map((item, index) => (
                    <div key={index} className="slide-item">
                    <Link
                    to={item.path}
                    className={`link-button custom-dot ${item.path === currentPath ? "active" : ""}`}
                    style={{ backgroundColor: item.backgroundColor }} // Apply the background color of the buttons here
                    >
                    {item.name}
                    </Link>
                    </div>
                    ))}
                </Slider>
							</div>
            </div>


		<div className='pillsAndClinicsContainer' style={{ backgroundColor: 'white' }}>
			
			<div className='pills-and-clinics-title'>{pageTitleOne}</div>
			<img className='mainImage'src={pageImageOne} alt='pills' />

			<div style={{maxWidth: '340px'}}>
				<div className='pills-and-clinics-page-description'>{pageDescriptionOne}</div>
					
					<div className='help-line-container'>
									<a
										href='tel:8448682812'
										style={{
											textDecoration: 'none', 
											color: 'inherit', 
											display: 'flex', 
											alignItems: 'center', 
										}}
									>
										{helpLineButton}
										<div className='phone-icon-container'>
											<img className='phone-icon' src={phoneIcon} alt='phone icon' />
										</div>
									</a>
				</div>
				</div>
			
			<div className='cardsContainer'>

{/* General Support Title */}
			<div className='pills-and-clinics-subtitle' styles={{marginTop: '100px'}}>{subTitleOne}</div>

{/* Planned Parenthood Card */}
  			<a href={linkOne}
				target='_blank' 
				rel='noopener noreferrer'>
   		 	<BasicCard
				image={cardOneLogo}
				title={cardOneTitle}
				content={cardOneDescription}
				arrow={arrowIconTwo}
				isCardOne={true}
    		/>
 			 </a>

{/* Pills Title */}
  		<div className='pills-and-clinics-subtitle'>{subTitleTwo}</div>

{/* Plan C Card */}
  			<a href='/resources-plan-c'>
   		  	<BasicCard
				image={cardTwoLogo}
				title={cardTwoTitle}
				content={cardTwoDescription}
				arrow={arrowIconTwo}
    		/>
  			</a>

{/* Abortion Pills in Private Card */}
			<a href={linkSeven}
				target='_blank' 
				rel='noopener noreferrer'>
   		  	<BasicCard
				image={cardSevenLogo}
				title={cardSevenTitle}
				content={cardSevenDescription}
				arrow={arrowIconTwo}
    		/>
  			</a>


{/* Aid Access Card */}
  			<a href={linkThree}
				target='_blank' 
				rel='noopener noreferrer'>
    		<BasicCard
      			image={cardThreeLogo}
    			title={cardThreeTitle}
      			content={cardThreeDescription}
				arrow={arrowIconTwo}
    		/>
  			</a>

{/* Abuzz Card */}
				<a href={linkSix} 
				target='_blank' 
				rel='noopener noreferrer'
				>
    		<BasicCard
				image={cardFourLogo}
				title={cardFourTitle}
				content={cardFourDescription}
				arrow={arrowIconTwo}
    		/>
  			</a>

{/* Clinics Near You Title */}
  		<div className='pills-and-clinics-subtitle'>{subTitleThree}</div>

{/* INeedAnA Card */}		
  			<a 	href={linkFour} 
				target='_blank' 
				rel='noopener noreferrer'
			>
    		<BasicCard
				image={cardFiveLogo}
				title={cardFiveTitle}
				content={cardFiveDescription}
				arrow={arrowIconTwo}
    		/>
  			</a>

{/* AbortionFinder Card */}
			<a 	href={linkFive}
				target='_blank' 
				rel='noopener noreferrer'
			>
    		<BasicCard
				image={cardSixLogo}
				title={cardSixTitle}
				content={cardSixDescription}
				arrow={arrowIconTwo}
				isCardOne={true}
    		/>
  			</a>
			</div>

{/* Support for people without papers and/or credit card Button */}			
			<div className='last-button contentfulMarkdown' allowDangerousHtml={true}>

  				<a href='/resources-more-info' allowDangerousHtml={true}>
   					 {lastButton}
   				 <img src={lastButtonArrow} alt='Arrow' className='arrow-image' allowDangerousHtml={true} />
  				</a>
			</div>

{/* Padding between content and Bottom Nav Bar */}
		<div className='end-of-page'></div>

{/* Bottom Nav Bar */}		
			<BottomNavBar appLanguage={props.appLanguage} />
		</div>
		</>
	)
}

export default PillsAndClinics